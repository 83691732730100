/* eslint-disable @typescript-eslint/no-explicit-any */
import { Fragment } from 'react';
import { Button, Grid, Paper, Typography } from '@mui/material';

import './HomePage.scss';
import { useNavigate } from 'react-router-dom';

function HomePage() {
  const navigate = useNavigate();
  return (
    <Fragment>
      <Grid container marginTop={2} justifyContent="center">
        <Grid item sx={{ maxWidth: 420 }}>
          <Paper sx={{ p: 3, ml: 2, mr: 2 }}>
            <Typography variant="h5" component="h5" sx={{ mb: 2 }}>
              Parkeringstillatelse for Politi og Toll i tjeneste
            </Typography>
            <Typography variant="body1" component="p" sx={{ mb: 2 }}>
              Politi og Toll i tjeneste kan fylle ut sjema for å parkere
              kostnadsfritt i 12 timer på Lufthavnene til Avinor som er regulert
              av Onepark.
            </Typography>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                marginTop: '26px',
              }}
            >
              <Button
                variant="contained"
                size="large"
                sx={{
                  pl: 4,
                  pr: 4,
                  backgroundColor: '#84216B',
                  '&:hover': {
                    backgroundColor: '#470A59',
                  },
                }}
                onClick={() => {
                  navigate(`/request/new`);
                }}
              >
                Fyll inn skjema
              </Button>
            </div>
          </Paper>
        </Grid>
      </Grid>
    </Fragment>
  );
}

export default HomePage;
