/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  Alert,
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  Grid,
  MenuItem,
  Paper,
  Select,
  SelectChangeEvent,
  TextField,
  Typography,
} from '@mui/material';
import { Fragment, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

//Types
import { AirportType } from '../../../types/types';

import api from '../../../utils/api';

import './style.scss';

function getLastDateOfMonth() {
  const currentDate = new Date();
  const year = currentDate.getFullYear();
  const month = currentDate.getMonth() + 1; // months are zero-based

  // Get the last day of the next month and subtract 1 to get the last day of the current month
  const lastDay = new Date(year, month, 0).getDate();

  // Format the date and time as a string
  const dateString = `${year}-${month.toString().padStart(2, '0')}-${lastDay
    .toString()
    .padStart(2, '0')}`;
  const timeString = '23:59';
  return `${dateString}T${timeString}`;
}

function getCurrentDate() {
  const currentDate = new Date();
  const year = currentDate.getFullYear();
  const month = currentDate.getMonth() + 1; // months are zero-based
  const day = currentDate.getDate();

  // Format the date and time as a string
  const dateString = `${year}-${month.toString().padStart(2, '0')}-${day
    .toString()
    .padStart(2, '0')}`;
  const timeString = '00:00';
  return `${dateString}T${timeString}`;
}

function formatDate(dateString: string): string {
  const date = new Date(dateString);

  const options: Intl.DateTimeFormatOptions = {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
  };

  return date.toLocaleDateString('nb-NO', options);
}

function ApplyNewWhitelistingPage() {
  const [airports, setAirports] = useState<AirportType[]>([]);
  const [airportsLoading, setAirportsLoading] = useState<boolean>(true);
  const [selectedAirport, setSelectedAirport] = useState<string>('');

  const [licencePlate, setLicencePlate] = useState<string>('');

  const [postLoading, setPostLoading] = useState<boolean>(false);
  const [postError, setPostError] = useState<boolean>(false);

  const [displaySendingResult, setDisplaySendingResult] =
    useState<boolean>(false);

  const [displaySendingResultError, setDisplaySendingResultError] =
    useState<boolean>(false);

  const [sendingResponse, setSendingResponse] = useState<any>();

  const navigate = useNavigate();

  //Load airports from server
  useEffect(() => {
    loadAirports();
  }, []);

  const loadAirports = async () => {
    const result = await api<any, any>(`/request/airports/get`, 'GET');
    if (!result) {
      setAirportsLoading(false);
      console.log('data');
    } else {
      setAirportsLoading(false);
      setAirports(result.airports);
    }
  };

  const handleChangeAirport = (event: SelectChangeEvent) => {
    setSelectedAirport(event.target.value);
  };

  const postRequestBtn = () => {
    setPostLoading(true);
    const data = {
      airport: selectedAirport,
      licence_plate: licencePlate,
    };
    postNewRequest(data);
  };

  const postNewRequest = async (data: any) => {
    const result = await api<any, any>(`/dispatch/new`, 'POST', data);
    if (!result) {
      setPostLoading(false);
      setPostError(true);
    } else {
      setPostLoading(false);

      if (result.status !== 'OK') {
        setPostError(true);
      } else {
        setDisplaySendingResult(true);
        setSendingResponse(result);
        setLicencePlate('');
        setTimeout(() => {
          setDisplaySendingResult(false);
        }, 12000);
      }
    }
  };

  return (
    <Fragment>
      {airportsLoading ? (
        <Grid container marginTop={2} justifyContent="center">
          <Grid item>
            <CircularProgress />
          </Grid>
        </Grid>
      ) : (
        <Box sx={{ flexGrow: 1, height: '100%' }}>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <FormControl sx={{ m: 1, mt: 3, minWidth: 320, maxWidth: 320 }}>
              <Alert severity="warning" sx={{ mb: 2 }}>
                Denne tjenesten kan kun benyttes av Politi og Toll i tjeneste.
                Ved utfylling av skjemaet før parkeringen er avsluttet, blir man
                ikke belastet for parkering.
                <br />
                <br />
                <b>
                  Parkeringstillatelsen er kun gyldig i 12 timer fra dette
                  skjemaet sendes inn.
                </b>
              </Alert>
            </FormControl>
          </div>

          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              marginTop: '26px',
            }}
          >
            <Typography variant="h5" component="p">
              Velg lufthavn:
            </Typography>
          </div>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <FormControl sx={{ m: 1, minWidth: 320, maxWidth: 320 }}>
              <Alert severity="info" sx={{ mb: 2 }}>
                Velg den lufthavnen der du skal ha parkeringstillatelse.
              </Alert>
              <Select
                value={selectedAirport}
                displayEmpty
                placeholder={'Velg lufthavn'}
                onChange={handleChangeAirport}
                sx={{ backgroundColor: 'white' }}
              >
                <MenuItem value="">
                  <em>Velg lufthavn</em>
                </MenuItem>
                {airports.map(el => {
                  return (
                    <MenuItem key={el.id} value={el.id}>
                      {el.name}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </div>
        </Box>
      )}

      {selectedAirport && (
        <Fragment>
          <Box sx={{ flexGrow: 1, height: '100%' }}>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                marginTop: '26px',
              }}
            >
              <Typography variant="h5" component="p">
                Oppgi kjøretøy:
              </Typography>
            </div>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <FormControl sx={{ m: 1, minWidth: 320, maxWidth: 320 }}>
                <Alert severity="info" sx={{ mb: 2 }}>
                  Oppgi registreringsnummeret til kjøretøyet som skal motta
                  parkeringstillatelse.
                </Alert>
                <TextField
                  id="license-plate"
                  label="Registreringsnummer"
                  value={licencePlate}
                  onChange={e => {
                    setLicencePlate(
                      e.target.value.replace(/[^a-z0-9]/gi, '').toUpperCase(),
                    );
                  }}
                  sx={{ mb: 2, backgroundColor: 'white' }}
                />
              </FormControl>
            </div>
          </Box>

          {postError && (
            <Grid container marginTop={2} justifyContent="center">
              <Grid item>
                <Paper
                  sx={{ p: 2, mb: 2, backgroundColor: 'red', maxWidth: 320 }}
                >
                  <Alert severity="error">
                    En feil har oppstått, og din forespørsel har ikke blitt
                    sendt inn. Prøv igjen.
                  </Alert>
                </Paper>
              </Grid>
            </Grid>
          )}

          {postLoading ? (
            <Grid container marginTop={2} justifyContent="center">
              <Grid item>
                <CircularProgress />
              </Grid>
            </Grid>
          ) : (
            <Box sx={{ flexGrow: 1, height: '100%' }}>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  marginTop: '16px',
                }}
              >
                <Button
                  disabled={!licencePlate}
                  onClick={postRequestBtn}
                  variant="contained"
                  size="large"
                  sx={{
                    pl: 4,
                    pr: 4,
                    mb: 10,
                    backgroundColor: '#84216B',
                    '&:hover': {
                      backgroundColor: '#470A59',
                    },
                  }}
                >
                  Få parkeringstillatelse
                </Button>
              </div>
            </Box>
          )}
        </Fragment>
      )}
      <Dialog
        open={displaySendingResult}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Parkeringstilatelse ble registrert.
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Parkeringstilatelse for <b>{sendingResponse?.licence_plate}</b> er
            registrert.
            <br />
            <br />
            Parkeringstilatelsen er gyldig i{' '}
            <b>{sendingResponse?.duration} minutter</b> fra kjøretøyet
            kjører/kjørte inn på parkeringsområdet.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setDisplaySendingResult(false);
              setPostLoading(false);
            }}
          >
            Lukk
          </Button>
        </DialogActions>
      </Dialog>
      <Backdrop sx={{ color: '#fff' }} open={postLoading}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </Fragment>
  );
}

export default ApplyNewWhitelistingPage;
